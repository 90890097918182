@import "../../../../variables";

.checkInsPanel {
  
  .grid {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
    border: none;
    
    .notApprovedRow {
    }
    
    .approvedRow {
      background-color: #28a74540 !important;
    }
    
  }
  
}