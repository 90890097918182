@import "../../../../../variables";

.distanceModal {

  .form {
    
    .buttons {
      width: 48%;
      margin-top: 10px;
    }
  }
}