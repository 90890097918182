@import "../../../variables";

.formPreview {
  > div:global(.athenaeum-page-container-container) {
    padding: 15px !important;
  }
  
  .help {
    display: flex;
    flex-direction: column;
    //margin-top: -15px !important;
    
    .clickStepText {
      width: 100%;
      font-size: 1.2em;
      padding-bottom: 2*$baseline;
      text-align: center;
    }
    
      button {
      margin-right: 0;
    }
    
    .inspectionInfo {
      display: flex;
      justify-content: space-between;
    }
  }
  .previewPageRow {

    padding: $baseline 15px;
    padding-bottom: 0 !important;
    display: flex;
    align-content: center;

    > button {
      margin-left: auto;
      margin-right: auto;
      height: 80px;
    }
  }
}