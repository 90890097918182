.form {

  .centerItem {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .questionContainer {
    border: 1px lightgrey solid;
    border-radius: 0.3rem;
    margin-bottom: 1rem;

    .question {
      padding: 6px 12px;
      border-top: 1px lightgrey solid;

      &:first-of-type {
        border-top: none;
      }

      > div {
        align-items: center;

        > div {
          &:nth-child(2) {
            display: flex;
            justify-content: center;

            > div {
              width: auto;
            }
          }
        }
      }
    }
  }
}