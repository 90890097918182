@import "../../../variables";

.addExtraChargeModal {
  .form {

    .buttons {
      width: 48%;
      margin-top: 10px;
    }
  }
}