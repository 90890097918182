@import "../../../variables";

.workOrder {

    .title {
        margin: $baseline/2 (-$baseline/2) !important;
    }

    .deviceActions {

        margin-left: auto;
        width: auto;

        .infoButton {
        }

        .priorityButton {
            text-transform: uppercase;

            &.isPriority {
                background-color: $orange !important;
                color: $white !important;
            }
        }

    }

    .failedDevice {
        span {
            color: $danger;
        }

        &.extraInfoDevice {
            span {
                cursor: pointer;
            }
        }
    }

    .container {
        width: 100%;
        padding-bottom: $baseline;
    }

    .data {
        padding-bottom: $baseline;

        .picture {
            width: 100%;
            height: 180px;
            display: flex;
            justify-content: center;
            background-size: contain !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
        }

        .image {
            display: flex;
            justify-content: center;
        }
    }

    .info {
        padding-bottom: $baseline;

        p {
            margin-bottom: $baseline / 2;
        }

        mark {
            color: initial;
            font-size: 1.2em;
        }
    }

    .table {
        width: 100%;
        font-size: 1.0em;

        td {
            padding: 2px 4px !important;
        }

        address {
            margin: 0;
        }
    }

    .danger {
        background-color: #fe5000;
        color: white;
        padding-left: 2px;
        padding-right: 2px;
        border-radius: 4px;
    }

    .bell {
        color: $orange !important;
    }
}