@import "../../variables";

.calendar {

  > div {
    display: flex;
    flex-direction: column;

    .pageHeader {

      text-transform: uppercase;
      margin-bottom: $baseline !important;

      hr {
        display: none;
      }
    }
  }

  .container {

    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    flex-grow: 1;

    .leftPanel {

      display: flex;
      flex-direction: column;
      height: 100%;

      > div > div > div > div > div > div > div > div {
        margin-bottom: 15px;
      }

      .tabHeaders {

        display: flex;
        flex-direction: row;
        box-shadow: $renta-shadow;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        .tab {

          display: flex;
          background-color: $light-gray;
          width: 100%;
          justify-content: center !important;

          &:first-child {
            border-top-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
          }

          > span {
            padding: 2*$baseline 4*$baseline;
            white-space: nowrap;
          }

          &.active {
            background-color: white;
            border-bottom: 6px solid $orange;
          }
        }
      }

      .listContainer {
        height: 100%;
        border: 1px solid lightgrey;
        border-top: none;
        border-radius: 3px;
        padding: 15px 15px 15px 15px;
        box-shadow: $renta-shadow;
      }
    }

    .rightPanel {
      padding-left: 2*$baseline;
      display: flex;
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }
}