@import "../../../variables";
 
.costPoolsData {

  .container {
    display: inline-block;
  }
  
  .toolbar {
    
    width: 100%;
    
    > div {
      width: 100%;
    }
    
  }
    
}