@import "../../../variables";

.formPictures {

    .title {
        text-align: center;
        width: 100%;
        margin-left: 2 * $baseline !important;
        margin-right: 2 * $baseline !important;
        font-size: 1.5em;
    }

    .photoContainer {
        padding-right: 5%;
        padding-left: 5%;

        :global(.athenaeum-image-input-fullScreen) {
            top: 0;
            height: 100%;
        }
    }
}