@import "../../../../variables";

.dailyHoursWidget {

    .extended {
        
        .distances {

            padding: $baseline 3*$baseline $baseline 3*$baseline;

            .hourItem {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-top: $baseline;
                margin-bottom: $baseline;

                > div {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &.row1 {                        
                    }

                    &.row2 {

                        .hours {
                            display: flex;
                            flex-direction: column;
                            
                            &:first-child {
                                > span:first-child {
                                    font-weight: bold;
                                }
                            }
                            
                            > span {
                                
                                &:first-child {
                                    font-size: 2em;
                                    line-height: 32px;
                                }
                                
                                &:last-child {
                                    font-size: 0.8em;
                                }
                            }
                        }
                    }

                    &.row3 {
                        align-items: center;
                        justify-content: center;
                        
                        .comment {
                            display: flex;
                            flex-direction: column;

                            > span {

                                &:first-child {
                                    font-size: 1em;
                                    line-height: 32px;
                                }

                                &:last-child {
                                    font-size: 0.8em;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        .addItem {

            &.noDistances {
                margin-bottom: 2*$baseline - 1;
            }

            display: inline-block;
            margin-bottom: $baseline;
            
            > span {
                padding-left: $baseline;
            }
        }
    }
}