@import "../../../variables";

.formNavigation {

  display: flex;

  > div {
    background-color: $orange;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    color: white;
    padding: $baseline;
    margin: auto;
    text-align: center;
    min-width: 40%;

    .step {
      font-size: 1.2em;
    }

    .index {
      font-size: 1.8em;
      font-weight: bold;
      min-height: 38px;

      input {
        width: 40px;
        padding: 0;
        height: 30px;
        text-align: center;
        margin-top: 4px;
      }
    }

    .icon {
      margin-top: $baseline;
      margin-bottom: $baseline;
    }

    .title {
      font-size: 1.2em;

      > span {
        padding-left: $baseline;
      }
    }
  }
  
}