@import "../../../../variables";

.workOrderDetails {
  width: 100%;
  margin-bottom:50px;
  
  .jobSummary {
    opacity: 1 !important;
    font-size: 16px;
    
    :global(.athenaeum-widget-container-valueContainer) {
      height: auto !important;
    }

    span {
      white-space: pre-line;
    }
  }

  .table {
    width: 100%;

    th {
      padding: 2px 4px;
      width: 40%;
    }

    td {
      word-break: break-word;
      padding: 2px 4px;
    }
  }

  .distancesTable {
    width: 100%;

    th {
      padding: 2px 4px;
    }

    td {
      padding: 2px 4px;
      word-break: break-word;
    }
  }
}