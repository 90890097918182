@import "../../../variables";

.workOrdersToolbar {

  #notAssignedCheckbox {

    // Fix label vertical alignment
    margin-bottom: 22px !important;
  }

  .dateRangeContainer {

    .dateDelimiter {
      display: flex;
      margin-bottom: 1.5rem;
    }
    
    align-items: end !important;
    
    :global(.input-group-text) {
      background-color: $orange;
      width: 35px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:hover {
        background-color: $button-hover;

        .clearDate {
          color: #212529;
        }
      }
    }

    :global(.input-group-append) {
      cursor: pointer;
      margin-left: -$baseline;
      
      .clearDate {
        justify-content: center;
        color: $white;
      }
    }
  }

  .topMarginButton {

    // Fix label vertical alignment
    margin-top: .75rem;
  }
}