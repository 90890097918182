@import "../../../variables";

.hoursAndDistances {
}

.workOrderJobSummary {

  :global(.athenaeum-widget-container-valueContainer) {
    height: auto !important;
  }
  
  span {
    white-space: pre-line;
  }
}