@import "../../../variables";

.formOptions {

    .title {
        text-align: center;
        width: 100%;
        margin-left: 2 * $baseline !important;
        margin-right: 2 * $baseline !important;
        font-size: 1.5em;
    }

    .item {
        margin: $baseline 2 * $baseline;
    }
}