.approveWorkOrderModal{
    
    margin-bottom: 20px;
    
    table.list {
        color: white;
        font-size: smaller;
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
        line-height: 1.4em;
    }

    table.list th {
        text-align: left;
        font-weight: normal;
        background-color: #FE5000;
        vertical-align: bottom;
        padding: 0.2cm 0.3cm;
    }

    table.list-small th {
        text-align: left;
        font-weight: normal;
        background-color: #FE5000;
        vertical-align: bottom;
        padding: 0.1cm 0.1cm;
    }

    table.list td {
        padding: 0.2cm 0.3cm;
    }

    table.list tr.header {
        background: #FEBBA5;
    }

    table.list tr.content {
        color: black;
        text-align: right;
    }

    table.list tr.content  {
        text-align: left;
    }


    th.small {
        height: 15px;
    }

    th {
        height: 30px;
    }
}