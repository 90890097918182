@import "../../variables";

.constructionSiteManagement {
    > div {
        > div {
            > div {                
                > div {
                    > ul {
                        margin-left: -$baseline !important;
                        margin-right: -$baseline !important;
                    }
                }                
            }
        }
    }
    
    .pageHeader {
        min-height: 58px;
        margin-bottom: 0 !important;

        .greenSelectedItem {
            :global {
                .selected-item {
                    color: green;
                }
            }
        }
        
        .button {
            background-color: #f3f3f3;
            border-color: transparent !important;

            &:hover {
                background-color: #e3e3e280 !important;
                border-color: #ebecea !important;
            }
        }
        
        .title {
            display: flex;
            align-items: baseline;
            margin-bottom: -6px;
            margin-left: -6px;
            
            .blue {
                color: #337ab7 !important;
            }

            .button {
                @extend .button;
                @extend .blue;
                
                background-color: #f3f3f3 !important;
                color: #337ab7 !important;
                padding: 2px 6px;
                margin-right: 4px;
                
                &:last-of-type {
                    margin-left: 4px;
                }
                
                &:hover {
                    background-color: #e3e3e280 !important;
                }
            }
        }
    }
    
    .contactPersonForm {
        > div {
            &:first-child {
                width: 100%;
            }

            &:nth-child(2) {
                margin-top: 10px;

                > button {
                    height: 35px;
                }
            }
        }
    }
    
}

.wideContainer {
    max-width: 75% !important;
}