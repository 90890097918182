@import "src/variables";

.newsCarousel {

  padding: 0;
  color: white;

  i {
    color: white !important;
  }

  span {
    background-color: white !important;
  }

  border-radius: 5px;

  > div {
    border-radius: 5px;
  }
}

.slide {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  text-align: center;
  height: 220px;
  
  @media screen and (max-width: $break-xs) {
    height: 360px;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    opacity: 0.3;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $orange; 
    z-index: 0;
  }

  .header {
    margin-top: auto;
    text-shadow: 0 0 3px darkslategray;
    z-index: 2;
  }

  .content {
    margin-bottom: auto;
    z-index: 2;
  }
  
  code {
    color: $blue;
  }
}