@import "../../../../variables";

.toolbar {
  padding: 0 $baseline;
  
  .dateDelimiter {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .filtersTopPadding {
    padding-top: .66rem;
  }

  :global(.input-group-text) {
    background-color: $orange;
    width: 35px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      background-color: $button-hover;

      .clearDate {
        color: #212529;
      }
    }
  }

  :global(.input-group-append) {
    cursor: pointer;
    margin-left: -$baseline;

    .clearDate {
      justify-content: center;
      color: $white;
    }
  }
}