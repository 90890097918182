@import "../../../variables";

.formReceivers {
    
    .receiverContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        > div {
            width: 100%;
        }
        
        i {
            color: $orange;
            margin-top: 2rem;
        }
    }
}