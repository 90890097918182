@import "src/variables";

hr {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.NewsEditor {
  
  .newsEditModal {
  }

  .carouselItemContainer {
    position: relative;
    padding: 0;
    color: white;
    margin-bottom: 15px;

    i {
      color: white !important;
    }

    span {
      background-color: white !important;
    }

    border-radius: 5px;

    > div {
      border-radius: 5px;
    }
  }

  .newsEditorOptions {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .newsItemContainer {
    position: relative;
    display: flex;
    flex-direction: column;

    .newsItemOptions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: $baseline;
    }

    .prioritySettings {
      display: flex;
      flex-direction: row;
      gap: $baseline;
    }

    .editOptions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $baseline;
    }
  }
}


.newsEditorForm {

  margin: 2*$baseline;

  .container {
    position: relative;
  }

  .helpIcon {
    position: absolute;
    right: 0;
    color: $orange;
  }

  .editFormOptions {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: $baseline;
  }
}

.helpContent {
  display: flex;
  flex-direction: column;
  white-space: normal;
  padding: $baseline;
  text-align: left;
  max-width: 500px;

  .helpContentRow {
    display: flex;
    flex-direction: row;
    gap: $baseline;
    padding: $baseline 0;

    .exampleCode {
      white-space: pre-wrap;
    }

    .helpContentCode {
      min-width: 120px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $baseline;
      justify-content: center;
      line-height: 2;
    }

    .helpContentRendered {
      display: table-cell;
    }

    code {
      color: $blue;
    }
  }
}
