@import "../../../../variables";

.taskHoursPanel {

  .grid {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
    border: none;
  }
  
}