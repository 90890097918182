@import "../../../../variables";

.formSummarySignatureTableRow {
  display: flex;
  flex-direction: column;
  padding: $baseline * 1.5;
  border-bottom: 1px solid grey;
  
  & img {
    width: 100%;
    max-width: 10rem;
    height: auto;
    padding: $baseline;
  }
}