@import "../../../../variables";

.formSummaryTableRow {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $baseline * 1.5;
  border: 1px solid grey;
  border-radius: $baseline;

  &.ok {
    border-left: $baseline * 1.5 solid $green;
  }

  &.inProgress {
    border-left: $baseline * 1.5 solid $blue;
  }

  &.fail {
    border-left: $baseline * 1.5 solid $danger;
  }

  .left, .right {
    .ok {
      color: $green;
    }

    .inProgress {
      color: $blue;
    }

    .fail {
      color: $danger;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $baseline;
    font-size: 80%;
  }
}