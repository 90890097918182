@import "../../../../variables";

.formSummaryTableGroup {
    padding: $baseline;
    border: 1px solid lightgray;
    border-radius: $baseline;
    margin-bottom: $baseline;

    h5 {
        margin-left: $baseline;
    }
}

.formSummaryTableGroupItemContainer {
    display: flex;
    flex-direction: column;
    gap: $baseline;
}