@import "../../variables";

.formDefinitions {

  .container {
  }

  .table {
    width: 100%;

    .formDefinitionItemRow {

      .itemInfo {
        padding-top: calc($baseline / 2);
        padding-bottom: calc($baseline / 2);
        
        span {
          width: 93%;
          display: block;
          padding: $baseline;
          color: $white;
          font-size: 1.2em;
          font-weight: bold;
          background-color: $orange;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          &.deleted {
            background-color: $blue;
          }
        }

        .copyItem {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          background-color: $grey;
          border-left: 1px white solid;
          border-radius: 0 3px 3px 0;

          i {
            padding: $baseline;
            color: $white;
          }
        }

        .hover {
          &:hover {
            transition: all 0.3s;
            color: $text-black !important;
            background-color: $button-hover !important;
            border-color: #adadad;
            
            i {
              color: $text-black !important;
            }
          }
        }
      }
    }
  }
}