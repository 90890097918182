.notificationSettings {
  width: 100%;

  .mainSettings {
    display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;
    align-items: center;

    &.mobile {
      display: block !important;
    }
  }

  .enableSwitch {
    margin-bottom: 8px;
  }

  :global(.athenaeum-number-input-numberInput) {
    max-width: 100px !important;
  }

  .timePicker {
    input {
      width: 130px;
    }
  }

  .headerRow {
    margin-bottom: 0 !important;
    margin-top: 30px;
  }

  .notificationSettingsContainer {
    .numberInput {
      input {
        width: 100px;
      }
    }
  }
}