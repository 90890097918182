@import "../../variables";

.workOrder {
  .notAssigned {
    color: $danger;
      > div > div > input {
        border-color: $danger !important;
      }
  }

  .assignContactPersonButton {
    margin-top: 10px;
  }

  .infoButton {
    margin-top: 10px;
    margin-left: $baseline;
  }

  .dropdownMaxWidth {
    max-width: 90% !important;
  }

  .section {
    margin-top: 11px;
  }

  .bell {
    color: $orange !important;
    cursor: pointer;
  }
}
