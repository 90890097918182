@import "../../variables";

.userSignatureWrapper {
  border-bottom: 2px solid $orange;
  padding: 1.25rem;

  & img {
    width: 100%;
  }
}

.deleteButton {
  display: block!important;
  margin: 1rem auto;
  width: 30%;
  min-width: 8rem;
  padding: 1rem;
}

.validationMessage {
  display: block!important;
  margin: 1rem auto;
  width: 30%;
  min-width: 8rem;
  padding: 1rem;
}