@import "../../../variables";

.viewTopMargin {
  margin-top: -0.66rem
}

.filtersTopMargin {
  margin-top: 0.66rem;
}

.calendarLegendModal {
  .dotsContainer {
    display: flex !important;
    flex-direction: row;

    .dotsColumn {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-left: 4*$baseline;
      }

      .dot {
        &:not(:first-child) {
          margin-top: 2*$baseline;
        }

        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
          width: 15px;
          height: 15px;
          border-radius: 50%;

          &.unscheduled {
            background-color: $taskStatusFilterUnscheduledColor;
          }

          &.upcoming {
            background-color: $taskStatusFilterUpcomingColor;
          }

          &.inProgress {
            background-color: $taskStatusFilterInProgressColor;
          }

          &.completed {
            background-color: $taskStatusFilterCompletedColor;
          }

          &.sentToCustomer {
            background-color: $taskStatusFilterSentToCustomerColor;
          }

          &.approvedByCustomer {
            background-color: $taskStatusFilterApprovedByCustomerColor;
          }

          &.declinedByCustomer {
            background-color: $taskStatusFilterDeclinedByCustomerColor;
          }

          &.readyForInvoicing {
            background-color: $taskStatusFilterReadyForInvoicingColor;
          }

          &.invoiced {
            background-color: $taskStatusFilterInvoicedColor;
          }
        }

        > span {
          padding-left: 2*$baseline;
          white-space: nowrap;
          text-align: center;
          color: $text-black;
          font-size: 1.1em;
        }
      }
    }
  }
}