@import "../../variables";

.signature {
    padding: 8px 0 12px 0;

    .signaturePad {
        background-color: #ff7e51;
        border-radius: 5px;
        width: 100%;
        height: 200px;
        cursor: pointer;
    }

    .buttonContainer {
        display: flex;
        justify-content: space-between;
        padding-top: $baseline;

        button {
            display: block;
            width: 100%;

            &:first-child {
                margin-right: $baseline;
            }
        }
    }
}
