@import "../../../variables";

.formChecks {

    .title {
        text-align: center;
        width: 100%;
        margin-left: 2 * $baseline !important;
        margin-right: 2 * $baseline !important;
        font-size: 1.5em;
    }
    
    .check {
        width: 100%;
        justify-content: space-between;
        
        > div {
            align-items: center;
        }
    }
}