.tasksPanel {
  .linkColumn {
    &:hover {
      text-decoration: underline;
    }

    div > span {
      cursor: pointer;
    }
  }
}
