@import "../../variables";

.formText {

  padding-top: 2 * $baseline;

  .description {
    text-align: center;
    width: 100%;
    margin-left: 2 * $baseline !important;
    margin-right: 2 * $baseline !important;
    font-size: 1.5em;
  }

  .text {
    width: 100%;
    margin-top: $baseline;
    margin-bottom: $baseline;
  }
}