@import "../../../variables";

.formButton {
  height: 80px;
  white-space: normal;
  outline: none !important;
  box-shadow: none !important;
}

.formSecretButton {
  background-color: transparent !important;
  border-radius: 25px;
  padding: 2px;
  margin: 0;
  width: 10em;
  height: 2.5em;
}

.formNoPointerEventsButton {
  pointer-events: none;
}