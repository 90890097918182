@import "../../variables";

.dateInputContainer {
    padding: 10px 0 12px 0;
}

.date {
    font-size: 2rem;
}

.dateInputWidget {
    background-color: $orange;
    border-color: white;
    
    .react-datepicker__triangle {
        border-bottom-color: #e04716;
    }

    .react-datepicker__navigation {
        &.react-datepicker__navigation--previous {
            border-right-color: white;
        }

        &.react-datepicker__navigation--next {
            border-left-color: white;
        }
    }

    .react-datepicker__header {
        background-color: #e04716;
        border-color: white;

        .react-datepicker__current-month {
            color: white;
        }

        .react-datepicker__day-name {
            color: white;
        }
    }

    .react-datepicker__day {
        color: white;

        &:hover {
            background-color: $orange-hover;
        }
    }

    .react-datepicker__day--today {
        border: 1px solid white;
        border-radius: 50%;
        height: 1.7rem;
    }

    .react-datepicker__day--selected {
        background-color: $blue;
    }
    
    .react-datepicker__today-button {
        background-color: #e04716;
        color: white;
        border-top-color: white;
    }
}
