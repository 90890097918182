@import "../../../variables";

.attachments {
  > div {
    padding: 15px !important;
  }

  .pageRow {
    padding: $baseline 15px;
    padding-bottom: 0 !important;
    display: flex;
    align-content: center;

    > button {
      margin-left: auto;
      margin-right: auto;
      height: 80px;
    }
  }

  .comment {
    border: 1px solid $orange;
    border-radius: 3px;
    width: 100%;
    display: block;
    font-size: 1.4em;
    font-weight: normal;
    padding: 2*$baseline;
  }

  .commentAttachment {
    position: relative;
    border-radius: 3px;
    border: 1px solid $orange;
    margin-top: $baseline;
    height: 100px;
    width: 100%;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: white !important;
  }
}