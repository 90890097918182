@import "../../../../variables";

.attachmentsList {
  min-width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter {
      padding: $baseline 14px;
      line-height: 0;
    }
  }

  .attachmentListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    background-color: $orange;
    color: white;
    padding: $baseline / 3 $baseline;
    margin: $baseline / 2 0;
    border-radius: $radius;

    &.noItems {
      justify-content: center;
      opacity: 0.8;
    }
  }
  
  .Pagination {

    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: $baseline;
    width: 80%;
    border-radius: 3px;

    div {
      width: 100%;

      span {
        width: 100%;
        text-align: center;
        display: block;
        font-size: 1.6em;
      }
    }
  }

  .date {
    width: 100%;
    margin-bottom: $baseline/2;

    > div:first-child {
      width: 100%;

      > div {
        width: 100%;

        > input {
          border-color: $orange;
          width: 100%;
        }
      }
    }
  }

  .table {
    width: 100%;

    td {
      padding-top: $baseline / 2;
      padding-bottom: $baseline / 2;
    }

    .icon {
      > span {
        background-color: $orange;
        border-radius: 3px;
        font-size: 1.2em;
        font-weight: bold;
        color: white;
        padding: $baseline;
        display: block;
        width: 100%;
        cursor: pointer;
        text-align: center;
      }

      &.minWidth {
        > span {
          min-width: 50px;
        }
      }

    }

    .info {
      @extend .icon;
    }

    .info {
      width: 100%;
      word-break: break-word;

      > span {
        display: flex;
        justify-content: space-between;
        text-align: left;

        > div {
          display: inline-block;
        }

        .attachmentItem {
          flex: 3;
        }

        .user {
          flex: 2;
          text-align: center;
          white-space: nowrap;
        }

        .twoLines {
          position: relative;
          min-width: 50px;

          span {
            display: block;

            &:nth-child(2) {
              font-size: 12px;
              line-height: 12px;
              font-weight: 100;
            }
          }

          &.topSpace {
            top: -3px;
          }
        }
      }
    }
  }
}