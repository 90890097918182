@import "../../../variables";

.myWorkHours {
    
    .approved {
        opacity: 1;

        a {
            background-color: #28a745 !important;

            &:hover {
                background-color: #28a745 !important;
            }
        }
    }
    
}