@import "../../../../variables";

.formsList {
  min-width: 100%;

  .date {
    min-width: 100%;
    margin-bottom: calc($baseline / 2);

    > div:first-child {
      width: 100%;

      > div {
        width: 100%;

        > input {
          border-color: $orange;
          width: 100%;
        }
      }
    }
  }

  .table {
    width: 100%;

    td {

      &.formName {
      }

      &.secondColumn {
      }

      &.actionButtons {

        display: flex;
        flex-direction: row;

        button {
          width: 50%;
          margin-right: 0.5em;

          &:last-of-type {
            margin-right: 0;
          }
        }

        @media screen and (max-width: $break-xs) {
          flex-direction: column;

          button {
            width: initial;
            margin-bottom: 0.5em;
            margin-right: 0;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}