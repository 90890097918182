@import "../../variables";
 
.warehouses {
  
  .toolbar {
    
    width: 100%;
    
    > div {
      width: 100%;
    }
    
  }
    
}