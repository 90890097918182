@import "../../../variables";

.formSummaryTable {

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $baseline;

  hr {
    width: 100%;
  }

  .receiverList {
    margin: 0;
    padding-left: $baseline * 2;
  }
  
  .actionButton {
    margin-bottom: $baseline;
  }
}