@import "../../../variables";

.workDayPanel {
  
  .fixedLineHeight {
    line-height: 12px;
  }

  .hidden {
    display: none;
  }
  
  .main {
    
    display: table;
    
    .left {
      display: table-cell;
      padding: 0 $baseline $baseline;
      vertical-align: top;
      min-width: 200px;
      max-width: 200px;
      font-size: 0.8rem;
      position: relative;

      > div {
        &:first-child {
          margin-bottom: $baseline;
        }
      }
    }
    
    .right {
      display: table-cell;
      padding: 0 $baseline $baseline;
      vertical-align: top;
      
      .workDayTable {
        margin-bottom: 0;

        .notApprovedRow {
        }

        .approvedRow {
          background-color: $approved !important;
        }

        .completedTask {
          > div:first-child {
            > div:first-child {
              > div:first-child {
                > div:first-child {
                  > div:first-child {
                    > div:first-child {
                      > span {
                        color: $blue;
                      }
                    }
                  }
                }
              }
            }            
          }
        }
      }
    }
  }
}