@import "../../../variables";

.categoryItem {
  cursor: pointer;

  .content {
    display: flex;
    overflow: hidden;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
      font-size: 1.5em;
      color: grey;
    }
  }

  &.creatingEditing {
    cursor: initial;

    :global(.athenaeum-layout-row) > * {
      height: fit-content;
      margin-top: auto;
      margin-bottom: 10px;
    }

    :global(.athenaeum-form-inputGroup) {
      margin: 0 !important;
    }

    .editButtons {
      margin: auto 0;
    }

    button {
      height: 35px;
      margin-right: 10px;
    }
  }
}