@import "../../variables";

.workOrderChangeStatusModal {

  > div > div > div {
    padding-top: $baseline*2 !important;
    display: flex;
    flex-direction: column;
  }

  .customHeader {
    text-align: center;

    > h5 {
      white-space: normal;
    }
  }

  .commentContainer {
    padding-top: 0.5rem;
    padding-bottom: 1rem !important;

    .comment {
      padding-top: 1rem;
    }
  }

  .expander {
    flex-grow: 1;
  }
}