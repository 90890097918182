.addRentalEquipmentModal {
  .searchIcon {
    cursor: pointer;
  }
  
  .searchResult {
    margin-bottom: 15px;
    
    > span {
      font-size: 0.9rem;
    }
  }
}