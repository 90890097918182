@import "../../variables";

.userManagement {

  .tabContainer {
    min-height: 40vh;
  }

  .invitations {
    margin-bottom: $baseline * 2;
  }
}

.padding{
  margin-top: 26px;
}