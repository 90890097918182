@import "../../variables";

.externalNotificationModal {
  > div > div > div {
    padding-top: $baseline*2 !important;
    display: flex;
    flex-direction: column;
  }

  .expander {
    flex-grow: 1;
  }

  .okButton {
    margin-top: $baseline*2;
  }
}