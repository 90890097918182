@import "../../../variables";

.formQuestionInput {

    .description {
        text-align: center;
        width: 100%;
        padding-bottom: $baseline;
        font-size: 1.5em;
    }

    .comment {
        width: 100%;
        margin-top: $baseline;
        margin-bottom: $baseline;
    }

    .questionStepButtons {
        flex: 0 0 33.3%;
        max-width: 33.3%;
        white-space: break-spaces;
    }

    :global(.athenaeum-image-input-fullScreen) {
        top: 0;
        height: 100%;
    }

    .imageInput {
        width: 100%;
    }
}