@import "../../../variables";

.calendarData {
  margin-top: 2*$baseline;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .3), 
              -23px 0 20px -23px rgba(0, 0, 0, .8),
              23px 0 20px -23px rgba(0, 0, 0, .8),
              0 0 40px rgba(0, 0, 0, .1) inset;
  
  > div > div {
    &:first-child {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 30px rgba(0, 0, 0, 0.1) inset;
    }
}

  .calendarItem {
    position: relative;
    background-color: white;
    padding-left: 4px;
    border-radius: $radius;
    border-bottom-width: thick;
    
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 3px;
    }

    &.pauseOverlay {
      position: absolute;
      margin-left: -4px;
      padding-left: 0 !important;
      min-width: 8px;
      align-items: center;
      top: 0;
      bottom: 0;
      background-color: $dark-gray;
      opacity: 0.3;
      border: none !important;
      box-shadow: none !important;
      border-radius: unset;
    }

    &.unscheduled {
      box-shadow: inset rgba($taskStatusFilterUnscheduledColor, 1) -1px -3px 8px;
      border: 1px $taskStatusFilterUnscheduledColor;
    }

    &.upcoming {
      box-shadow: inset rgba($taskStatusFilterUpcomingColor, 1) -1px -3px 8px;
      border: 1px $taskStatusFilterUpcomingColor;
    }

    &.inProgress {
      box-shadow: inset rgba($taskStatusFilterInProgressColor, 1) -1px -3px 5px;
      border: 1px solid $taskStatusFilterInProgressColor;
    }

    &.completed {
      box-shadow: inset rgba($taskStatusFilterCompletedColor, 1) -1px -3px 8px;
      border: 1px solid $taskStatusFilterCompletedColor;
    }

    &.sentToCustomer {
      box-shadow: inset rgba($taskStatusFilterSentToCustomerColor, 1) -1px -3px 8px;
      border: 1px solid $taskStatusFilterSentToCustomerColor;
    }

    &.approvedByCustomer {
      box-shadow: inset rgba($taskStatusFilterApprovedByCustomerColor, 1) -1px -3px 8px;
      border: 1px solid $taskStatusFilterApprovedByCustomerColor;
    }

    &.declinedByCustomer {
      box-shadow: inset rgba($taskStatusFilterDeclinedByCustomerColor, 1) -1px -3px 8px;
      border: 1px solid $taskStatusFilterDeclinedByCustomerColor;
    }

    &.readyForInvoicing {
      box-shadow: inset rgba($taskStatusFilterReadyForInvoicingColor, 1) -1px -3px 8px;
      border: 1px solid $taskStatusFilterReadyForInvoicingColor;
    }

    &.invoiced {
      box-shadow: inset rgba($taskStatusFilterInvoicedColor, 1) -1px -3px 8px;
      border: 1px solid $taskStatusFilterInvoicedColor;
    }
  }
}

.tooltip {
  text-align: left;
}

.header {
  background-color: #ffffff;
  display: flex;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 30px rgba(0, 0, 0, 0.1) inset;
}

.teamHeader {
  @extend .header;
  text-transform: uppercase;
}

.mounterHeader {
  @extend .header;
}

.teamColor {
  height: 100%;
  width: 5px;
  margin-right: 5px;
}

.mounterHeader > .teamColor {
  margin-right: 15px;
}