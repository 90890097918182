@import "../../../variables";

.catalogItem {
  display: flex;
  border-radius: $radius;
  border-color: $light-gray;
  border-style: solid;
  border-width: 2px;
  padding: $radius;
  margin-top: 14px;
  align-items: center;

  .icon {
    display: flex;
    min-width: 54px;
    padding: 0;
    margin: 0 6px;
    justify-content: center;
    color: #fe5000;
  }

  &:not(:first-child) {
    margin-top: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}