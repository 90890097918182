@import "../../variables";

.workOrderTypeManagement {
  .container {
    width: 100%;
    display: inline-block;
  }

  .grid {
    width: 100% !important;
  }

  .toolbar {

    width: 100%;

    > div {
      width: 100%;
    }
  }

  .disabled {
    color: orangered;
  }
}