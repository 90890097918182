@import "../../../variables";

.workOrderForms {

  padding: 0;

  > * {
    border-radius: 0 !important;
  }

  .backButton {
    height: 80px;
    text-transform: none;
  }

  .help {
    display: flex;
    flex-direction: column;

    button {
      margin-right: 0;
    }

    .tabBlock {
      margin-left: auto;
      margin-right: auto;
    }
  }
}