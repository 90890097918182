@import "../variables";

.pageContainer {
    padding: 0 !important;
    
    > div {
        border-radius: 0 !important;
        height: 100%;
        padding: $baseline/2 !important;
    }
    
    .alert {
        //@extend .dashboardAlert; 
        max-width: 628px;
        margin-top: 0;
        margin-bottom: 4px;
        text-align: center;
        background: $transparent-dark-orange;
        border: none;
        cursor: default;
        color: white;
        border-radius: 5px;

        @media screen and (max-width: $break-md) {
            margin: 0 6px 8px 6px;
        }
    }
    
    .myWorkOrderWidget {
        a {
            background: $blue !important;

            &:hover,
            &:focus {
                background: $blue-transparent !important;
            }
        }
    }

    .newsCarouselOuterContainer {
        padding: 4px 6px;
        position: relative;
        width: 100%;
        .newsCarouselContainer {
            text-decoration: none;
            color: $white;
            background: rgb(254, 80, 0);
            border-radius: 5px;
            box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
            text-align: center;
            height: 100%;
            width: 100%;
        }
    }
    
    .newsCarousel {
        margin: auto;
        // magical number to set it same width as the Widget items.
        max-width: 628px;
    }
    
    .pageRow {
        margin: 0 -4px 4px !important;
        padding: $baseline 15px;
        padding-bottom: 0 !important;
        display: flex;
        align-content: center;

        > button {
            margin-left: auto;
            margin-right: auto;
            height: 80px;
        }
    }
}

.pageHeader {

    > div {

        padding-top: 0.2rem;
        padding-bottom: 0.2rem;

        > div {
            width: 100%;
            text-align: center;
        }
    }

    > hr {
        display: none;
    }
}

.leftPageHeader {
    @extend .pageHeader;

    > div {
        > div {
            text-align: left;
            margin: auto;

            h4 {
                margin-bottom: 0;
                line-height: 1.5;
            }
        }
    }
}

.rightPageHeader {
    text-align: right;
    margin-left: 4px;
    font-size: 18px;
    white-space: normal;
}

.navigation {
    @media only screen and (max-width: $break-md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.bigButton {
    height: 60px;
    white-space: normal;
    outline: none !important;
    box-shadow: none !important;
    text-transform: uppercase;

    :global {
        .actions-container {
            top: 58px;
        }

        .actions-icon {
            top: 16px !important;
            font-size: 24px !important;
        }
    }
}