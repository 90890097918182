@import "../../variables";

.hours {

    .header {
        .total {
            font-size: 3em;
            //font-weight: bold;
        }
    }
    
    .compact {
        font-size: 3em;
        line-height: 42px;
        margin-right: 10px;
    }

    .comment {
        display: flex;
        flex-direction: column;

        > span {

            &:first-child {
                font-size: 1em;
                line-height: 32px;
            }

            &:last-child {
                font-size: 0.8em;
            }
        }
    }
    
    .embeddedWidget {
        align-content: center;
        padding: 0 0 $baseline/2;
        margin: 0;

        > a {
            box-shadow: none !important;
            background: transparent !important;
            padding: 0;

            > div > div {
                padding: 10px 0 14px !important;
            }

            > span {
                font-size: 1rem;
                font-weight: normal;
            }
        }
        
        &.total {
        }
        
        &.inner {
            
            &:last-child {
                margin-bottom: 1.5*$baseline;
            }
            
            > a {
                
                > div > div {
                    padding: 0 !important;
                    
                    > div > div {
                        display: flex;
                        align-content: center;
                        justify-content: center;

                        span {
                            font-size: 2rem;
                            font-weight: 700;
                            line-height: 40px;
                        }
                    }

                    > input {
                        margin-bottom: 3px !important;
                        margin-top: 2px !important;
                    }
                }
                
                > div > span {
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
        }
    }

    .alignNumbers {
        a > div > div > div > div > span {
            margin-left: -15px;
            margin-right: 15px;
        }
    }
}