@import "../../../variables";

.rentalEquipment {

  $width: 60px;
  $height: 42px;
  $padding: 0 $baseline;
  $color: dimgrey;

  display: flex;
  flex-direction: column;
  padding: $baseline;
  align-items: initial;

  &.active {
    border-color: $orange;
  }

  &:not(:first-child) {
    margin-top: 0;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &:last-child {
    margin-bottom: 4px;
  }

  textarea:focus, input:focus {
    box-shadow: none;
    border-color: $blue;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: $height;

    > div {
      display: flex;
      justify-content: center;
      align-content: center;

      &.favorite {
        min-width: $width;
        cursor: pointer;
        color: $orange;

        &.noFavorite {
          visibility: hidden;
        }
      }

      &.name {
        width: 100%;
        color: dimgray;
        justify-content: left;
        align-items: center;
        font-size: 0.8rem;
        padding: $padding;

        > span {
          max-height: $height;
          overflow: hidden;
          word-break: break-word;
        }
      }

      &.initialAmount {
        min-width: $width;

        > span {
          font-weight: bold;
          color: dimgray;
          font-size: 1.1rem;
          overflow: hidden;
        }
      }
    }

  }

  .content {

    display: flex;

    .actions {
      min-width: $width;
      display: flex;
      flex-direction: column;
      justify-content: start;

      > div {
        display: flex;
        height: $height;
        align-items: center;
        position: relative;
      }

      .delta {
        position: absolute;
        right: 80%;
        top: 25%;
        font-size: 10px;

        border-radius: 2rem;
        background: #fff;
        color: gray;
        height: 20px;
        overflow: hidden;
        border: 1.5px solid $grey;
        display: flex;
        text-align: center;
        text-decoration: none;
        justify-content: center;
        width: auto;
        min-width: 20px;
        padding: 4px;
        line-height: 8px;
      }

    }

    .data {
      padding: 0;
      width: 100%;

      .input {
        padding: $padding;
        display: flex;
        flex-direction: column;

        span {
          height: $height;
          word-break: break-word;
          overflow: hidden;
          font-weight: bold;
          font-size: 1.5rem;
          color: $color;
          justify-content: center;
        }

        input {
          height: $height;
          text-align: center;
          font-size: 1.3rem;
        }

      }

      .description {
        display: flex;
        align-items: center;

        > span {
          padding: $padding;
          overflow: hidden;
          color: $color;
          white-space: break-spaces;
          overflow-wrap: anywhere;
        }
      }

    }

  }

  .descriptionInput {
    margin-top: 6px;
  }

}