.teamModal {
  
  .teamMembers {
     > div > div {
       margin-bottom: 5px;
     }

    margin-top: -15px;
    
    ul {
      padding-left: 15px;
    }

    li {
      list-style-type: none;
      padding: 0;
      margin-top: 5px;
      overflow: auto;
    }

    button {
      float: right;
    }
  }

  .picker {
    width: 100% !important;
  }

  .colorPicker {
    
    > input {
      border: none;
      background-color: #cfcfcf;
      border-radius: 3px !important;
      min-width: 10px !important;
      max-width: 35px !important;
      padding: 0 !important;
      min-height: 35px !important;
    }
  }

  .buttonsContainer {
    margin-top: 15px;
  } 
}