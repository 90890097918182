@import "../../../variables";

.breadCrumb {

  display: flex;

  div {
    display: flex;
    max-height: 100%;
    overflow: hidden;
  }

  .item {
    z-index: 99;

    color: white;
    height: 39px;
    display: flex;
    align-items: center;
    margin-bottom: 1px;

    h1 {
      font-size: 14px;
      margin: 0;
      height: 100%;
      align-content: center;
      display: flex;
      background-color: $orange;

      a {
        cursor: pointer;
        display: flex;
        padding-left: 12px;
        padding-right: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 500px;
        overflow: hidden;
        align-items: center;
        justify-items: center;

        > span {
          display: inline-block;
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    img {
      height: 100%;
      background-color: $orange;
    }

    &.prevLast {
      img {
        background-color: #bd3e15;
      }
    }

    &.last {
      h1 {
        background-color: #bd3e15;
      }
      img {
        background-color: transparent;
      }
    }

  }

}