@import "../../variables";

.formDefinition {

  mark {
    padding: 0;
    background-color: transparent;
    color: white;
    font-style: italic;
  }

  .marginRightButtons {
    margin-right: 300px;
  }

  .table {
    width: 100%;

    tr {
    }

    td {
      &:first-child {
        padding-top: calc($baseline / 2);
        width: 100%;
      }

      &:nth-child(2) {
        vertical-align: top;
      }

      > span {
        background-color: $orange;
        border-radius: 3px;
        color: white;
        font-size: 1em;
        font-weight: bold;
        padding: $baseline;
        display: block;
        width: 100%;

        small:first-child {
          padding-left: $baseline;
          display: inline;
        }

        small:nth-child(2) {
          display: block;
        }

        > div {
          > div {
            :global {
              label.validation-error {
                color: white !important;
                font-style: italic;
              }
            }
          }
        }

      }

      .dropdown {

        display: inline-block;

        > div {
          > div {
            > div {

              > div {

                > span {
                  display: none;
                  padding: $baseline;
                }

                > div {
                  > div {
                    > div {
                      span {
                        white-space: nowrap;
                      }
                    }
                  }
                }

                > i {
                  margin: 0;
                }

                &:nth-child(2) {
                    width: auto;
                    left: auto;
                    right: 0;
                }
              }
            }
          }
        }
      }

      .checkbox {
        color: white;
        margin-bottom: 15px;

        &.auto {
          width: auto;
          display: inline-block;
        }

        i {
          color: white;
        }

        label {
          cursor: pointer;
        }
      }
    }

    .add, .up, .down, .delete {
      padding: calc($baseline / 4);
      color: $blue;
      text-align: center;
    }

    .add {
      padding-left: $baseline;
      color: darkgreen;
    }

    .delete {
      color: $orange;
    }

    .disabled {
      color: lightgray;
      pointer-events: none;
    }

    .headerRow {

      td {

        width: 100%;
        text-align: center;

        &:nth-child(2) {
          vertical-align: middle;
        }

        span {
          background-color: lightgrey;
        }

        input {

          outline: none !important;
          border-radius: 3px;
          background-color: lightgrey;
          border: 1px solid lightgrey;
          color: white;
          font-size: 1em;
          font-weight: bold;
          padding: $baseline;
          display: block;
          width: 100%;

          &:focus, &:active {
            outline: none !important;
          }
        }
      }
    }
  }

  .buttons {
    margin-top: 2*$baseline;
  }
}