@import "../../variables";

.statusMessageModal {
  > div > div > div {
    padding-top: $baseline*2 !important;
    display: flex;
    flex-direction: column;
  }

  .customHeader {
    text-align: center;
    padding-bottom: $baseline*2;

    > h5 {
      white-space: normal;
    }
  }

  .expander {
    flex-grow: 1;
  }

  .okButton {
    margin-top: $baseline*2;
  }
}