@import "../../variables";

.catalog {
  width: 100%;

  &.shoppingCart {
    padding-left: 6px;
    padding-right: 6px;
  }

  .breadCrumbs, .search, .editButtons {
    margin-top: $baseline;
    margin-bottom: $baseline;
  }

  .editButtons button {
      margin-right: 5px;
  }

  .search {
    :global(.input-group-append) {
      cursor: pointer;
    }
  }

  .searchTitle {
  }

  .list {
    @media screen and (max-width: $break-sm) {
      margin-bottom: 50px;
    }
  }

  .addButtonsContainer {
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    gap: .5rem 1rem;
  }
  
  .threeColumnsGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .twoColumnsGrid {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: $break-xs) {
    .threeColumnsGrid {
      grid-template-columns: 1fr;
    }
  }

  .add, .noProducts {
    width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: $grey;
    border-radius: $radius;
    border-style: solid;
    border-width: 1px;
    color: $grey;
    height: 8 * $baseline;
    margin-bottom: $baseline;
  }

  .add {
    padding: 2 * $baseline;
    cursor: pointer;
    &:hover {
      border-color: $orange;
    }

    > span {
      padding-left: $baseline;
    }
  }

  .noProducts {
  }
  
  .deleted {
    background-color: #e3e3e3;;
  }
}

.createModal > div {
  max-width: 700px;
}